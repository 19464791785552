<template>
  <InputSelect
    title=""
    name="language-select"
    isRequired
    class="mb-0"
    valueField="id"
    textField="display_name"
    :options="languageList"
    v-model="selectLanguage"
    @onDataChange="onChange($event)"
  />
</template>

<script>
export default {
  data() {
    return {
      languageList: [],
      selectLanguage: this.$store.state.currentLanguage,
    };
  },
  created() {
    this.getDisplayLang();
  },
  methods: {
    async getDisplayLang() {
      const resp = await this.$store.dispatch("getLanguageList");
      this.selectLanguage = this.$store.state.currentLanguage || 1;
      this.languageList = resp.data.detail;
    },
    onChange(val) {
      this.$store.commit("setCurrentLanguage", val);
      this.$emit("changeLang", val);
    },
    setValue(val) {
      this.selectLanguage = val;
      this.$store.commit("setCurrentLanguage", val);
      this.$emit("changeLang", val);
    },
  },
};
</script>

<style></style>
